<template>
  <v-card
    class="px-15 px-sm-25 py-15 py-sm-27 mainBg mx-10 rounded-lg login-card"
    max-width="500"
    width="500"
    outlined
    flat
  >
    <div v-if="!!errorMessage">
      <div class="accentColor--text title pb-10 pb-sm15">
        {{ errorMessage }}
      </div>
      
      <div class="d-flex justify-end pt-sm-10">
        <v-btn
          class="defaultBg px-10"
          x-large
          depressed
          height="54"
          @click="goToLogin"
        >Login</v-btn>
      </div>
    </div>
    
    <v-form ref="form" v-model="valid" v-else>
      <div class="accentColor--text display-1 pb-5">
        Welcome to SoltexPay
      </div>
      
      <div class="accentColor--text title pb-10 pb-sm15">
        Set your account password
      </div>
      
      <div>
        <div class="pb-7">
          <span class="secondaryColor--text">Password</span>
        </div>
        <v-text-field
          v-model="password"
          background-color="defaultBg"
          flat
          solo
          type="password"
          :rules="passwordRules"
        />
      </div>
      
      <div>
        <div class="pb-7">
          <span class="secondaryColor--text">Confirm Password</span>
        </div>
        <v-text-field
          v-model="confirmPassword"
          background-color="defaultBg"
          flat
          solo
          type="password"
          :rules="confirmRules"
        />
      </div>
      
      <div class="d-flex justify-end pt-sm-10">
        <v-btn
          class="defaultBg px-10 mr-8"
          x-large
          depressed
          height="54"
          @click="goToLogin"
        >Login</v-btn>
        
        <v-btn
          class="secondary px-10"
          x-large
          depressed
          height="54"
          type="submit"
          @click.prevent="submitForm"
          :loading="isLoading"
        >Confirm</v-btn>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Invite',
  data: () => ({
    valid: true,
    password: null,
    confirmPassword: null,
    passwordRules: [],
    confirmRules: [],
  }),
  computed: {
    ...mapState({
      isLoading: state => state.invite.loading,
      successRegister: state => state.invite.successRegister,
      errorMessage: state => state.invite.errorMessage,
    })
  },
  mounted() {
    this.checkInviteToken(this.$route.params.token)
  },
  methods: {
    ...mapActions({
      checkInviteToken: 'invite/CHECK_INVITE_TOKEN',
      registerInvitedUser: 'invite/REGISTER_INVITED_USER',
      resetState: 'invite/GET_RESET_STATE',
    }),
    async submitForm() {
      await this.validationForm()
      if (this.$refs.form.validate()) {
        this.registerInvitedUser({
          token: this.$route.params.token,
          password: this.password
        })
      }
    },
    goToLogin() {
      this.$router.push({ name: 'Login' })
    },
    validationForm() {
      this.passwordRules = [
        v => !!v || 'This field is required'
      ]
      this.confirmRules = [
        v => !!v || 'This field is required',
        v => v === this.password || 'Passwords must match'
      ]
    }
  },
  watch: {
    successRegister: {
      handler(value) {
        if (value) {
          this.goToLogin()
        }
      }
    }
  },
  beforeDestroy() {
    this.resetState()
  }
}
</script>
